import React from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '50%',
        height: '50%',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        'text-align': 'center',
        // filter: 'drop-shadow(3px 3px 3px black)',
    },
});

const BlockedPage: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h1" gutterBottom>
                Sorry
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Come back when you`re a little older.
            </Typography>
        </div>
    );
};

export default BlockedPage;
